<template>
    <div class="share-coupon-qrcode">
        <div class="qrcode-box">
            <div class="qrcode-img">
                <img id="qrcode" src />
            </div>
            <div class="qrcode-text">
                <p>
                    长按保存图片，分享到朋友圈，
                    <br />与好友一起来领券 !
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            qrcode: "",
        };
    },
    async mounted() {
        // this.getQrcode();

        const cavs = document.createElement("canvas");
        cavs.width = 700;
        cavs.height = 1247;
        const ctx = cavs.getContext("2d");
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, 700, 1247);


        function imgDrawPromise(ctx, src ,x, y, w, h) {
            return new Promise((resolve, reject) => {
                const oimg = new Image();
                oimg.src = src;
                oimg.crossOrigin = "anonymous";
                oimg.onload = () => {
                    ctx.drawImage(oimg, x, y, w, h);
                    resolve(ctx);
                    console.log(oimg, "图片加载成功");
                };
                oimg.onerror = () => {
                    reject(new Error("图片加载失败"));
                };
            });
        }
            //this.domin + localStorage.getItem("coupon_qrcode")
            // require("../assets/wzxercode.png")
        try {
            await imgDrawPromise(ctx,require("../assets/quanBg.jpg"), 0, 0, 700, 1247);
            await imgDrawPromise(ctx,this.domin + localStorage.getItem("coupon_qrcode"), 500, 1055, 120, 120);
            document.getElementById("qrcode").setAttribute("src", cavs.toDataURL());
            console.log("图片绘制完成");
        } catch (error) {
            console.log("error => ", error);
        }
    },
};
</script>

<style>
.qrcode-img{
    margin:  0;
    padding: 2px;
    text-align: center;
}
.qrcode-text {
    text-align: center;
    font-weight: bold;
    color: #333;
    font-size: 16px;
    line-height: 30px;
    

}
.qrcode-img img {
    width: 100%;
    box-shadow: 0 0 10px #ccc;
}
</style>